<template>
  <div
    style="
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 234, 220, 1) 100%
      );
      height: 100vh;
    "
  >
    <section>
      <div class="container-fluid">
        <div class="row">
          <!-- <div class="col-md-8 custom-d-sm-none p-0">
                    <div class="img-container">
                        <img src="./images/car_showroom_04.jpg" style="width:100%; height: 100vh;">
                    </div>
                </div> -->

          <div class="col-md-12 sm-pad" style="padding-right: 0">
            <div class="log-flex-wrap animate__animated animate__zoomIn">
              <div class="login-form-wrap">
                <!-- <h3>Welcome to TTS Portal</h3> -->
                <img
                  src="../../assets/images/logo.png"
                  class="img-fluid mb-4 animate__animated animate__rubberBand"
                  alt="logo"
                  style="animation-delay: 0.2s"
                />
                <p>Please sign-in to your account</p>
                <!-- <div class="dropdown mb-3 login-select">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    Select
                                </button>
                                <ul class="dropdown-menu login-ul" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Partner</a></li>
                                    <li><a class="dropdown-item" href="#">Project Manager</a></li>
                                    <li><a class="dropdown-item" href="./escalate-dashboard.html">Head CSR</a>
                                    </li>
                                   
                                </ul>
                            </div> -->
                <!-- <p v-if="errors.length > 0">
                                <div class="alert alert-danger alert-dismissible" role="alert" v-for="error in errors">
                                    <strong> {{ error }}</strong>
                                </div>
                            </p> -->
                <ValidationObserver ref="loginForm">
                  <form>
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <div class="form-label">
                        <label class="frm-label">Email</label>
                        <input
                          type="email"
                          name="Email"
                          v-model.trim="credentials.USER_NAME"
                          class="form-control"
                          placeholder="Enter Email"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null && credentials.USER_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-label">
                        <label class="frm-label">Password</label>
                        <div class="input-pass">
                          <input
                            :type="show_password ? 'text' : 'password'"
                            name="Password"
                            v-model="credentials.PASSWORD"
                            class="form-control"
                            id="password"
                            placeholder="Enter Password"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null && credentials.PASSWORD != '',
                            }"
                            @keyup.enter="login()"
                          />
                          <button
                            type="button"
                            id="eye"
                            @click="show_password = !show_password"
                            class="eye-btn border-0"
                          >
                            <i
                              v-if="!show_password"
                              class="fa fa-eye"
                              aria-hidden="true"
                              style="color: #000"
                            ></i>
                            <i
                              v-else
                              class="fa fa-eye-slash"
                              aria-hidden="true"
                              style="color: #000"
                            ></i>
                          </button>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </div>
                    </ValidationProvider>
                    <div class="form-label mt-3">
                      <a @click="forget" class="forgt-pass"
                        >Forget Password ?</a
                      >
                    </div>
                    <div class="form-label">
                      <a
                        class="login-btn"
                        @click="login()"
                        style="cursor: pointer"
                        >Log In</a
                      >
                    </div>
                    <!-- <div class="form-label">
                                    <a class="proposal-btn modal-dialog-centered" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Submit Project Proposal</a>
                                </div> -->
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered access_code_modal">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Project Proposal</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                       <div class="row">
                           <div class="col-md-12">
                               <input type="text" class="form-control" placeholder="Enter Access Code">
                           </div>
                           <div class="col-md-6 mt-3">
                               <a href="./proposal.html" class="btn-success custom-btn">Go To Proposal</a>
                           </div>
                       </div>
                    </div>
                    
                  </div>
                </div>
              </div> -->

        <!-- static modal  -->
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal_success">
            <div class="modal-content">
              <div class="modal-header" style="background: #bfe0eb">
                <h5 class="modal-title" id="staticBackdropLabel"></h5>
                <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
              </div>
              <div class="modal-body" style="background: #f8f8f8">
                <div class="row">
                  <div class="col-md-12">
                    <p class="">
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style="color: red"
                      ></i>
                      You Need To Fill-Up Onboarding Details.
                    </p>
                  </div>
                  <div class="col-md-6 mt-3">
                    <a
                      href="./partner_onboarding/partner-onboarding.html"
                      class="custom-btn btn-success"
                      >Fill Up Onboarding</a
                    >
                  </div>
                </div>
              </div>
              <!-- <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-primary">Understood</button>
                    </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      // auth_user: {
      // TempLogin: "",
      //         },
      show_password: false,
      credentials: {
        USER_NAME: "",
        PASSWORD: "",
      },

      errors: [],
      user_name_details: {
        USER_NAME: "TemporaryToken",
        AUTH_KEY:
          "6481f8e1a060d56eeb7c10ac7809d316800dce013713c412e1d22076505b11a8",
        flag: "",
        userA: 0,
        userB: 1,
      },
      TempLogin: "",
    };
  },
  mounted() {
    if ($cookies.isKey("_token")) {
      // let user = localStorage.getItem('lek_user_details');
      // if (user) {
      //   this.TempLogin = user.TempLogin;
      //   this.redirect();
      // }
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(
            true,
            "error",
            "User Name And Password Are Required!"
          );
          return;
        } else {
          this.axios
            .post(apiEndPoints.login, {
              USER_NAME: this.credentials.USER_NAME,
              PASSWORD: this.credentials.PASSWORD,
            })
            .then((response) => {
              if (response.data.status == "true") {
                this.user_details = response.data.response;
                $cookies.set("_token", response.data.Token, { expires: "23h" });
                localStorage.setItem(
                  "lek_user_details",
                  JSON.stringify(response.data)
                );
                localStorage.setItem(
                  "ets_user_details",
                  JSON.stringify(response.data.UserId)
                );
                this.authuser();
                this.redirect();
                this.getUserRolePrivillegeInfo(response.data.UserId);
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.Response);
              }
            })
            .catch((err) => {
              if (err.data.status == false || err.data.status == "false") {
                Global.showErrorAlert(true, "error", err.data.response);
              }
              if (err.response.status != 401 || err.response.Status != 403) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      });
    },
    // authuser(){
    //     console.log("function call")
    // let user = localStorage.getItem('lek_user_details');
    //               if (user) {
    //                     user = JSON.parse(user)};
    //                                 console.log( "yrop", user)
    //                                   this.TempLogin = user.TempLogin;
    //                                   console.log("rijuy", this.TempLogin )
    //    },

    //   redirect(){
    //       console.log("riju")

    //             if(this.TempLogin =="Y"){
    //              this.$router.push({ name: "changepasstemp" });
    //                 }
    //             else  {
    //                  this.$router.push({ name: "dashboard" });
    //                  }
    //   },

    forget() {
      this.$router.push({ name: "forget-pass" });
      this.axios
        .post(apiEndPoints.temporary_token, this.user_name_details)
        .then((response) => {
          console.log(response);
          if (response.data.Status == null) {
            $cookies.set("_token", response.data.Token, 0);
            localStorage.setItem(
              "lek_user_detai",
              JSON.stringify(response.data)
            );
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.Response);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserRolePrivillegeInfo(userId) {
      this.axios
        .get(apiEndPoints.get_user_role_privilege_info, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: userId,
          },
        })
        .then((response) => {
          localStorage.setItem("my_menu", JSON.stringify(response.data));
          setTimeout(() => {
            //this.$router.push({ name: "dashboard" });
          }, 1000); // should wait for 1 second
        })
        .catch((err) => {
          if (err.data.status == false || err.data.status == "false") {
            Global.showErrorAlert(true, "error", err.data.response);
          }
          if (error.response.status != 401 || error.response.Status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    authuser() {
      let user = localStorage.getItem("lek_user_details");
      if (user) {
        user = JSON.parse(user);
      }
      this.TempLogin = user.TempLogin;
    },

    redirect() {
      if (this.TempLogin == "Y") {
        this.$router.push({ name: "changepasstemp" });
      } else {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>
